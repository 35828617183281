import React from "react";
import { Link, graphql } from "gatsby";
import Layout from "../components/layout";
import shakingHands from "../images/shakingHands.jpg";

import SEO from "../components/seo";

const Service = ({ title }) => {
  return (
    <div className="w-full sm:w-1/2 md:w-1/3 p-2">

      <div className="text-2xl text-indigo-700">{title} </div>

      {/*     
      <Link
        to={url}
        className="text-2xl text-indigo-700 hover:text-indogo-600 hover:underline"
      >
        {title}
      </Link>
      <p>{children}</p>
      */}    
      
    </div>
  );
};

const Services = ({ data }) => {
  const services = data.allMarkdownRemark.edges;

  return (
    <Layout headerClass="bg-white relative">
      <SEO title="Services" />  


      <div
        className="min-h-screen pt-12 sm:pt-16 md:pt-32 -mt-12 sm:-mt-8 md:-mt-24 lg:-mt-28 mb-20 bg-size-5/6 md:bg-size-4/5 lg:bg-size-2/3 bg-right-top bg-no-repeat flex flex-col items-center"
        
      >

          <div className="lg:px-64 md:px-32 sm:px-16">
            <img src={shakingHands} />
          </div>

        <div className="pt-6 sm:pt-8 md:pt-16 font-serif font-hairline self-start">
          <h1 className="text-3xl md:text-5xl text-indigo-700 leading-tight">
            Services
          </h1>
        </div>

        <div className="pt-4 sm:pt-8 md:pt-8 text-gray-800 self-center">
          <p>Candor Investigation Services now provides professional 
 investigation services to a major insurance company in SA, WA and NT.
         </p>
         <p>Our highly skilled and experienced investigators offer an investigation package, quick response times and a detailed reporting process within the time frames required.
         </p>
         <p>
           Through arrangements with other agencies we offer the following services:
         </p>

        </div>

        <div className="flex flex-wrap mt-10 md:mt-20">
          {services.map(({ node }) => (
            <Service
              title={node.frontmatter.title}
            >
            </Service>
          ))}
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query ServicesQuery {
    allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/content/services/" } }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      edges {
        node {
          frontmatter {
            title
          }
        }
      }
    }
  }
`;

export default Services;
